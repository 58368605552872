/* eslint-disable no-shadow */
import { zipObject } from "lodash";

export const state = () => ({
  characters: []
});

export const mutations = {
  setCharacters(state, characters) {
    state.characters = characters;
  }
};

export const actions = {
  async loadCharacters() {
    const url = `https://docs.google.com/spreadsheets/d/e/2PACX-1vSTP9TftzNYVIId3jArHyowLc0jbrt2MTZBraXbfA57NZQzEi2YuWVW03ffktZ8hpGOB8dElx3JCu3z/pub?output=csv`;

    try {
      let response = await this.$axios.$get(url);
      console.log(response);
      response = response.replace(/\r/g, "");
      let rows = response.split("\n");
      rows = rows.map(row => row.split(","));
      console.log(rows);
      const properties = rows.shift();
      const characters = [];
      for (const i in rows) {
        characters.push(zipObject(properties, rows[i]));
      }
      this.commit("aristeia/setCharacters", characters);
    } catch (e) {
      console.log(`axios error: ${e}`);
    }
  }
};
