import { firestoreAction } from "vuexfire";

export const state = () => ({
  activeDraft: {
    currentBidder: 1,
    characters: [],
    currentCharacter: null,
    countdownThreshold: 3, // The number of wins a player gets before we start counting down
    picksRemaining: null,
    player1: {
      currentBid: null,
      name: "Player 1",
      points: 10,
      characters: [],
      number: 1,
      hasBid: false,
      id: null
    },
    player2: {
      currentBid: null,
      name: "Player 2",
      points: 10,
      characters: [],
      number: 2,
      hasBid: false,
      id: null
    },
    draftPicks: []
  },
  draftId: null,
  playerId: null
});

export const actions = {
  resetBids() {
    this.commit("bidding/setPlayerBid", {
      player: 1,
      points: null
    });
    this.commit("bidding/setPlayerBid", {
      player: 2,
      points: null
    });
  },
  assignRandomDraftId() {
    const id =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    // this.commit("bidding/setDraftId", "test-record");
    this.commit("bidding/setDraftId", id);
  },
  assignRandomPlayerId() {
    const id =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    // this.commit("bidding/setDraftId", "test-record");
    this.commit("bidding/setPlayerId", id);
  },
  setDraftsRef: firestoreAction(function(context, ref) {
    context.bindFirestoreRef("allDrafts", ref);
  }),
  setActiveDraftRef: firestoreAction(function(context, ref) {
    context.bindFirestoreRef("activeDraft", ref);
  })
};

export const mutations = {
  setDraftId(state, id) {
    state.draftId = id;
  },
  setPlayerId(state, id) {
    state.playerId = id;
  }
};
